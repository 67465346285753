import React from "react"
import { graphql } from "gatsby"
import MyHelmet from "../components/MyHelmet"
import ImpressPageTemplate from "./ImpressPageTemplate"

const ImpressPage = ({ data }) => {
  const { frontmatter: fm } = data.markdownRemark
  return (
    <>
      <MyHelmet title={fm.title} description="" />
      <ImpressPageTemplate
        heading={fm.title}
        subheading={fm.subheading}
        image={fm.image}
        html={data.markdownRemark.html}
      />
    </>
  )
}

export default ImpressPage

export const impressQuery = graphql`
  query Impress($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        subheading
        image {
          childImageSharp {
            gatsbyImageData(width: 1216, placeholder: BLURRED)
          }
        }
      }
    }
  }
`
