import React from "react"
import Content from "../components/Content"
import { Container, Hero } from "../components/Sections"
import Header from "../components/Header"

const ImpressPageTemplate = ({ heading, subheading, image, html }) => {
  return (
    <>
      <Header heading={heading} subheading={subheading} />
      <Hero image={image}/>
      <Container>
        <Content html={html} />
      </Container>
    </>
  )
}

export default ImpressPageTemplate
